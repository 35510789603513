.plans-container{
    display: flex;
    flex-direction: column;
    margin-top: -4rem;
    padding: 0 2rem;
    gap: 4rem;
    position: relative;

}
.plans{
    display: flex;  
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    width: 15rem;
    padding: 1.5rem;
    gap: 2rem ;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);    
    width: 2rem;
    height: 2rem;

}
.plan>:nth-child(2){
  font-size: 1rem;
  font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
  }

  .features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .feature{
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .feature>img{
    width: 1rem;
  }

 @media screen  and (max-width:786px) {
    .plans{
        flex-direction: column;
    }
    .plan:nth-child(2){
        transform: none;
      
    }
  
 } 
  
